<template>
    <div id="index">
        <img id="bg-img" src="../assets/bg.png">
        <el-form ref="ruleForm">
            <el-form-item prop="name">
                <h4>姓名<img src="../assets/must.png"></h4>
                <el-input placeholder="输入姓名" v-model="ruleForm.name">
                    <template slot="suffix">
                        <el-button  @click="showHistoryPeople" round size="mini" type="primary">历史预约</el-button>                        
                    </template>
                </el-input>
            </el-form-item>
            <el-form-item prop="name">
                <h4>手机号<img src="../assets/must.png"></h4>
                <el-input placeholder="输入手机号" v-model="ruleForm.mobile"></el-input>
            </el-form-item>
            <el-form-item prop="name">
                <h4>身份证号<img src="../assets/must.png"></h4>
                <el-input placeholder="输入身份证号" v-model="ruleForm.id_number"></el-input>
            </el-form-item>
            <el-form-item prop="name">
                <h4>检测方式<img src="../assets/must.png"></h4>
                <el-select placeholder="选择检测方式" v-model="ruleForm.exam_id" style="width: 100%;">
                    <el-option v-for="(item, index) in options" :label="item.title" :value="item.id" :key="index"></el-option>
                </el-select>
                <h4 style="margin-top: 20px;" v-if="tipTitle" @click="shw_tip = true">{{tipTitle}}</h4>
            </el-form-item>
            <el-row style="margin-top: 100px;">
                <el-col :span="24" class="el-row--flex is-justify-center">
                    <el-button class="btn-lang" type="primary" @click="submitForm('ruleForm')">提交预约</el-button>
                </el-col>
                <el-col :span="24" class="el-row--flex is-justify-center" style="margin-top: 10px;">
                    <el-button class="btn-lang" type="text" @click="history()">查看预约</el-button>
                </el-col>
            </el-row>
        </el-form>
        <el-dialog
        title="选择历史预约人"
        :visible.sync="dialogVisible"
        width="90%"
        :before-close="handleClose">
            <el-radio-group @change='chooseHistoryPeople' v-model="historyPeople">
                <el-radio style="margin-top: 10px" v-for="item in historyPeopleData" :label="item.id_number">{{item.full_name}}  {{item.id_number}}</el-radio>
            </el-radio-group>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog
            fullscreen="true"
            :title="tipTitle"
            :visible.sync="shw_tip">
            <span>{{tipContent}}</span>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="shw_tip = false">关 闭</el-button>
            </span>
        </el-dialog>
        <p class="tips">
            @汉元科技提供技术支持 2021-2022
        </p>
    </div>
</template>

<script>
export default {
    name: "index",
    data() {
        return {
            shw_tip: false,
            ruleForm: {},
            tipTitle: '',
            tipContent: '',
            options: [],
            dialogVisible: false,
            historyPeopleData: [],
            historyPeople: 0
        };
    },
    created() {
        let _token = this.$route.query.token;
        let _userToken = localStorage.getItem('user-token');
        if(!_userToken || _userToken == 'undefined') {
            if(!_token || _token == undefined) {
                // location.href = this.$common.BASE_HOST + 'index/wxOauth.html';
            }else {
                localStorage.setItem('user-token', _token);
                this.samplingList();
            };
        }else {
            this.samplingList();
        };
    },
    methods: {
        chooseHistoryPeople(e){
            console.log(e)
            for(let i in this.historyPeopleData){
                if(this.historyPeopleData[i].id_number == e){
                    
                    this.ruleForm.name = this.historyPeopleData[i].full_name 
                    this.ruleForm.mobile = this.historyPeopleData[i].mobile
                    this.ruleForm.id_number = this.historyPeopleData[i].id_number
                    console.log(this.ruleForm)
                }
            }
        },
        showHistoryPeople(){
            this.$post('api/substance/yyHistory')
                .then(res => {
                    console.log(res)
                    if(res.error == 0){
                        if(res.datas == ''){
                            this.$message('暂无历史预约');
                        }else{
                            this.historyPeopleData = []
                            this.historyPeopleData = res.datas
                            this.dialogVisible = true
                        }
                        
                    }
                });
        },
        handleClose(){
            this.dialogVisible = false
        },
        samplingList() {
            this.$post('api/substance/samplingList')
                .then(res => {
                    this.options = res.datas;
                });
            this.$post('api/index/tips')
                .then(res => {
                    this.tipTitle = res.datas.title;
                });
            this.$post('api/index/tipDetail')
                .then(res => {
                    this.tipContent = res.datas.detail;
                });
        },
        // openTips() {
        //     this.$router.push({
        //         path: "/tips"
        //     });
        // },
        submitForm() {
            if (!this.ruleForm.name) return;
            if (!/^1[3|4|5|6|7|8|9][0-9]{9}$/.test(this.ruleForm.mobile)) {
                this.$message.warning('电话号码格式有误');
                return
            };
            let _ID = this.ruleForm.id_number;
            if(_ID.substr(-1) === 'x') {
                _ID = _ID.substr(0, _ID.length - 1) + 'X';
            };
            this.ruleForm.id_number = _ID;
            if (!/(^\d{15}$)|(^\d{17}([0-9]|X)$)/.test(this.ruleForm.id_number)) {
                this.$message.warning('身份证号码格式有误');
                return
            };
            if (!this.ruleForm.exam_id) return;
            let _that = this, _curExam = null;
            this.options.forEach(item => {
                if(item.id == _that.ruleForm.exam_id) {
                    _curExam = item;
                };
            });
            this.$router.push({
                path: "/pay",
                query: {
                    ..._curExam,
                    ...this.ruleForm
                }
            });
        },
        history() {
            this.$router.push({
                path: "/list"
            });
        }
    }
};
</script>
<style scoped>
    #index {
        padding: 30px 25px;
        position: relative;
    }
    #bg-img {
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
    h4 {
        color: #122F56;
        margin: 0;
        margin-top: 10px;
        line-height: 20px;
    }
    h4 img {
        width: 8px;
        margin-left: 5px;
    }
    .tips {
        color: #888;
        text-align: center;
        font-size: 14px;
    }
    #index >>> .el-input__inner {
        border: none;
        padding: 0;
        border-bottom: 1px solid #EFEEF1;
    }
    #index >>> .btn-lang {
        width: 90%;
        border-radius: 30px;
    }
    #index >>> .el-button--primary {
        background-color: #007FFF;
        box-shadow: 2px 2px 10px -3px #409EFF;
    }
    #index >>> .el-button--text {
        color: #007FFF;
    }
</style>